import React from 'react';

// import Link from "components/ui/link";
import Layout from 'layout';
import 'sass/theme/terms.scss';

const Tos = () => (
  <Layout
    metaTitle="Newsletter Terms & Conditions | Session Replay Software"
    canonical="/terms-of-service/"
    metaDescription="Read LiveSession’s Newsletter terms and conditions. Here you’ll find all terms of use in one place."
  >
    <section className="terms">
      <h1>NEWSLETTER TERMS AND CONDITIONS</h1>

      <h2 className="mt-5">What is Newsletter?</h2>

      <p>
        Newsletter - a free of charge service provided via e-mail which consists in provision by
        Livesession to the User of information on services - among others: new blog posts, offers
        and updates about new features. Newsletter is sent to an e-mail address indicated by the
        User.
      </p>

      <h2>Who provides the Newsletter?</h2>
      <p>
        LiveSession – LiveSession sp. z o.o. (Ltd.) with its registered office in Wroclaw, Poland,
        Gen. W. Sikorskiego Street no. 3/2, 53-659 Wroclaw, entered into the registry of
        entrepreneurs under the KRS number 0000753134, the registry files maintained by the District
        Court for Wroclaw - Fabryczna in Wroclaw, VI Commercial Division of the National Court
        Registry with the share capital in the amount of 5.000 PLN, with the NIP (tax identification
        number) no. 8971860301 and REGON no. 38158038000000.
      </p>
      <h2>To whom the Newsletter is addressed?</h2>
      <p>
        The Newsletter is addressed to the User - an entity with full capacity to perform acts in
        law, which is using the newsletter service in compliance with these Terms and Conditions,
        after voluntary consent.
      </p>

      <p>
        By subscribing to our Newsletter you agree to receive emails from LiveSession. We’d like to
        keep you updated about everything that might be interesting and valuable for you in relation
        to the services we provide.
      </p>
      <p>
        Subscribing to our Newsletter is not mandatory. By subscribing to our Newsletter, you agree
        to the following conditions:
      </p>
      <p>
        <strong>Main conditions</strong>
      </p>
      <ol type="1">
        <li>
          The Newsletter subscription service is provided free of charge to the Users who give their
          voluntary consent.
        </li>
        <li>
          User can activate Newsletter subscription on the website available at the address:{' '}
          <a href="https://livesession.io" target="_blank" rel="noopener noreferrer">
            https://livesession.io
          </a>
          . Using the Newsletter service requires a device with an Internet connection, an installed
          and updated Internet browser and an active e-mail address.
        </li>
        <li>
          To subscribe to the Newsletter, the User has to:
          <ol>
            <li>
              provide their e-mail address through a dedicated form available on website{' '}
              <a href="https://livesession.io" target="_blank" rel="noopener noreferrer">
                https://livesession.io
              </a>
              ;
            </li>
            <li>accept the content of these Terms and Conditions and Privacy Policy; </li>
            <li>sign up with the “Subscribe” button;</li>
            <li>
              activate the Newsletter through a link included in an e-mail sent to the User by
              LiveSession in response to subscription.
            </li>
          </ol>
        </li>
        <li>
          The abovementioned steps bring the result that LiveSession and User conclude an agreement
          for provision of the Newsletter service for an unlimited period of time.
        </li>
        <li>
          The User shall retain the right to terminate the agreement for the provision of the
          Newsletter service with immediate effect by deactivating their subscription. To exercise
          the right to terminate the agreement User shall activate a relevant link provided in the
          footnote of each email sent as part of the Newsletter service.
        </li>
        <li>
          LiveSession reserves the sole right to either modify or discontinue the Newsletter, at any
          time, with or without prior notice. LiveSession will not be liable to you or any third
          party should we exercise such right. LiveSession also reserves the sole right to
          unsubscribe Users from the Newsletter service without prior notice, especially when it’s
          suspected that the User has registered with fake data.
        </li>
      </ol>
      <p>
        <strong>Privacy policy</strong>
      </p>
      <p>
        LiveSession is the processor of personal data provided by the Users. LiveSession processes
        data for the purpose of providing the Newsletter service only. Such data may be transferred
        to a third party provider of services for LiveSession. The terms and conditions that
        regulate processing of personal data are prescribed by the Privacy Policy, which is an
        integral part of these{' '}
        <a href="/terms-of-service/" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>
        .
      </p>
      <p>
        <strong>Complaints</strong>
      </p>
      <ol type="1">
        <li>
          The User has the right to file a complaint about the Newsletter service. The complaint
          should include at least:
          <ol>
            <li>details that enable the identification of the User;</li>
            <li>comments concerning the Newsletter. </li>
          </ol>
        </li>
        <li>
          The complaint should be sent to the e-mail address{' '}
          <a href="mailto:hello@livesession.io">hello@livesession.io</a>. LiveSession will reply to
          the complaint within 14 business days, unless the User failed to describe the subject
          matter and the scope of complaint in a manner that enables its consideration, or failed to
          provide data that enables identification of the User.
        </li>
      </ol>
      <p>
        <strong>Final provisions</strong>
      </p>
      <ol>
        <li>
          LiveSession reserves the sole right to amend these Terms and Conditions due to material,
          legal or technical reasons.
        </li>
        <li>
          The Users will be notified on any amendments to the Terms and Conditions via e-mail sent
          10 (ten) days before the new wording of the Terms and Conditions comes into effect. The
          User can accept them or refuse to accept and terminate the agreement for the provision of
          the Newsletter service.
        </li>
        <li>
          Agreement and performance hereunder will be governed by and construed in accordance with
          the laws of Poland.{' '}
        </li>
      </ol>
    </section>
  </Layout>
);

export default Tos;
